<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section">
          <h1>Медиа</h1>
          <MediaFilter />
          <MediaList />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import MediaList from "@/views/mycity/media/components/MediaList.vue";
import MediaFilter from "@/views/mycity/media/components/MediaFilter.vue";

export default {
  name: "MayorMediaPage",
  async asyncData({ store }) {
    await store.dispatch("GET_MYCITY_MEDIA_PAGE");
  },
  components: {
    MediaList,
    MediaFilter,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.filterData = null;
    this.$store.state.filterResponse = null;
    this.$store.state.filterList = [];
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/mayor/index.styl"
@import "~@/styles/parts/mycity/poster/posters.styl"
@import "~@/styles/parts/home/poster.styl"
</style>
