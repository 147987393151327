<template>
  <i class="icon-medium">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="18.75" stroke="white" stroke-width="2.5" />
      <path
        d="M35 24L18.5 33.5263L18.5 14.4737L35 24Z"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "VideoPlayIcon",
};
</script>
