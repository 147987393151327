<template>
  <div class="mayor__media">
    <div>
      <div v-if="galleries && galleries.data && galleries.data.length" class="_items_list mayor__media-list">
        <router-link
          v-for="(item, index) in galleries.data"
          :key="index"
          :to="{
            name: 'media_item',
            params: { id: item.id },
          }"
          class="mayor__media-item"
        >
          <div class="mayor__media-img">
            <div class="img__container">
              <div class="img__upper__layer"></div>
              <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
            </div>
          </div>
          <div class="poster__tabs__content__wrap mayor__media-content">
            <div class="poster__tabs__content__title" v-if="item.title">
              {{ item.title }}
            </div>
            <div class="poster__tabs__content__info mayor__media-info">
              <div>
                <div class="poster__tabs__content__category" v-if="item.category && item.category.title">
                  {{ item.category.title }}
                </div>
              </div>
              <div class="poster__tabs__content__date">
                <MediaIcon v-if="item.type.code === $store.state.type.CODE_PHOTO_GALLERY" />
                <VideoPlayIcon v-if="item.type.code === $store.state.type.CODE_VIDEO_GALLERY" />
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div v-if="galleries && galleries.data && galleries.data.length" class="media-mobile__list">
        <router-link
          v-for="(item, index) in galleries.data"
          :key="'a' + index"
          :to="{
            name: 'media_item',
            params: { id: item.id },
          }"
          class="media-mobile__item"
        >
          <div class="media-mobile__img">
            <div class="img__container">
              <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
            </div>
          </div>
          <div v-if="item.title" class="media-mobile__title">
            {{ item.title }}
          </div>
          <div class="media-mobile__bottom">
            <div v-if="item.category && item.category.title" class="media-mobile__category">
              {{ item.category.title }}
            </div>
            <div v-if="item.type && item.type.code" class="media-mobile__icon">
              <MediaIcon v-if="item.type.code === $store.state.type.CODE_PHOTO_GALLERY" />
              <VideoPlayIcon v-if="item.type.code === $store.state.type.CODE_VIDEO_GALLERY" />
            </div>
          </div>
        </router-link>
      </div>

      <p v-else-if="!$store.state.filterResponse" class="_item__text">Медиа контента нет</p>
      <p v-else-if="$store.state.filterData.get('title')" class="_item__text">
        По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
      </p>
      <p v-else class="_item__text">По вашему запросу ничего не найдено</p>
    </div>

    <a v-if="loading" href="#" class="show-more-link">
      <LoadingIndicator title="Загрузка" />
    </a>
    <a
      @click.prevent="submitFilterForm"
      v-else-if="$store.state.filterResponse && $store.state.filterResponse.next_page_url"
      href="#"
      class="show-more-link"
    >
      Показать ещё
    </a>
    <a @click.prevent="loadMore" v-else-if="galleries.next_page_url" href="#" class="show-more-link">
      Показать ещё
    </a>
  </div>
</template>

<script>
import axios from "axios";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MediaIcon from "@/components/svg/MediaIcon.vue";
import VideoPlayIcon from "@/components/svg/VideoPlayIcon.vue";

export default {
  name: "MediaList",
  components: {
    LoadingIndicator,
    MediaIcon,
    VideoPlayIcon,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    galleries() {
      if (this.$store.state.filterResponse) {
        return this.$store.state.filterResponse;
      }
      return this.$store.state?.mycity_media_page?.galleries;
    },
  },
  methods: {
    /**
     * Фильтр медиа
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        await this.$store
          .dispatch("POST_MYCITY_MEDIA_FILTER", {
            data: this.$store.state.filterData,
            url: this.$store.state.filterResponse.next_page_url,
          })
          .then((response) => {
            this.loading = false;
            const oldResponse = this.$store.state.filterResponse;
            this.$store.state.filterResponse = response.data.galleries;
            this.$store.state.filterResponse.data = oldResponse.data.concat(
              this.$store.state.filterResponse.data
            );
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Загрузить еще медиа
     * @returns {Promise<void>}
     */
    async loadMore() {
      if (!this.loading) {
        this.loading = true;
        await axios
          .get(this.galleries.next_page_url)
          .then(({ data }) => {
            this.loading = false;
            // добавляем к существующим новые документы с новой страницы пагинации
            this.$store.state.mycity_media_page.galleries.data =
              this.$store.state.mycity_media_page.galleries.data.concat(data.galleries.data);
            this.$store.state.mycity_media_page.galleries.next_page_url = data.galleries.next_page_url;
          })
          .catch(({ response }) => {
            this.loading = false;
            console.error(response?.data?.message);
          });
      }
    },
  },
};
</script>

<style lang="stylus">

@import "~@/styles/mixins/ratio.styl"
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/parts/mayor/index.styl"
</style>
