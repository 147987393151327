<template>
  <div class="filter-form__pb filter__form-change-wrap">
    <form
      @submit.prevent="submitFilterForm"
      ref="filterForm"
      class="filter__form filter__form-change"
      autocomplete="off"
    >
      <div class="search filter">
        <div class="search__input">
          <label for="posters_search" class="search__label">Слово для поиска</label>
          <div class="search__input__wrap">
            <input v-model="form.title.value" id="posters_search" type="text" maxlength="255" />
            <button v-if="form.title.value" type="button" @click="resetSearchInput">
              <CloseIcon />
            </button>
          </div>
        </div>
        <div class="filter__item filter__select">
          <label>Тематический каталог</label>
          <Multiselect
            track-by="id"
            label="title"
            v-model="form.category_id.value"
            :options="sortedMainCategories"
            placeholder="Все темы"
            selectLabel="Выбрать ↵"
            deselectLabel="Удалить ↵"
            selectedLabel="Выбрано"
            :searchable="true"
            :allow-empty="true"
          >
            <span slot="noOptions">Список пуст</span>
          </Multiselect>
        </div>
        <div class="filter__item filter__date">
          <label>Диапазон дат</label>
          <div class="filter__date__inputs">
            <div class="filter__date__input">
              <flat-pickr v-model="form.start_date.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
              </flat-pickr>
              <DatePickIcon />
            </div>
            <div class="filter__date__input">
              <flat-pickr v-model="form.end_date.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
              </flat-pickr>
              <DatePickIcon />
            </div>
          </div>
        </div>
        <div class="search__btn search__btn--with-clear">
          <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
          <button type="submit" class="btn-blue_dark" :disabled="loading">
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else>Найти</template>
          </button>
        </div>
      </div>
      <ul class="media-tabs">
        <li
          class="media-tab media-tab--faded"
          :class="{
            'media-tab__btn--active': form.code_gallery.value === null,
          }"
          @click="filterContent(null)"
        >
          <button type="button" class="media-tab__btn">
            <MediaIcon />
            <span class="media-tab__title"> Все медиа </span>
          </button>
        </li>
        <li
          class="media-tab media-tab--faded"
          :class="{
            'media-tab__btn--active': form.code_gallery.value === $store.state.type.CODE_VIDEO_GALLERY,
          }"
          @click="filterContent($store.state.type.CODE_VIDEO_GALLERY)"
        >
          <button type="button" class="media-tab__btn">
            <VideoIcon />
            <span class="media-tab__title"> Видео </span>
          </button>
        </li>
        <li
          class="media-tab media-tab--faded"
          :class="{
            'media-tab__btn--active': form.code_gallery.value === $store.state.type.CODE_PHOTO_GALLERY,
          }"
          @click="filterContent($store.state.type.CODE_PHOTO_GALLERY)"
        >
          <button type="button" class="media-tab__btn">
            <PhotoIcon />
            <span class="media-tab__title"> Фотографии </span>
          </button>
        </li>
      </ul>
    </form>

    <a
      @click.prevent="changeFilterForm"
      class="filter__change-btn"
      :class="{ hide__btn: isFilterVisible }"
      href="#"
    >
      <span v-if="isFilterVisible">Скрыть фильтр</span>
      <span v-else>Поиск и фильтр по медиа</span>
      <ArrowDownIcon />
    </a>
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Multiselect from "vue-multiselect";
import DatePickIcon from "@/components/svg/DatePickIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import MediaIcon from "@/components/svg/MediaIcon.vue";
import VideoIcon from "@/components/svg/VideoIcon.vue";
import PhotoIcon from "@/components/svg/PhotoIcon.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "MediaFilter",
  components: {
    FlatPickr,
    Multiselect,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    PhotoIcon,
    MediaIcon,
    VideoIcon,
    ArrowDownIcon,
  },
  data() {
    return {
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        defaultHour: 0,
        defaultMinute: 0,
        dateFormat: "d.m.Y H:i",
        disableMobile: true,
      },
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        start_date: {
          value: null,
          message: null,
        },
        end_date: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
        code_gallery: {
          value: null,
          message: null,
        },
      },
    };
  },
  computed: {
    mainCategories() {
      if (this.$store.state.main_categories && this.$store.state.main_categories.length) {
        return this.$store.state.main_categories.filter((item) => {
          return item.status.code === this.$store.state.status.CODE_ACTIVE;
        });
      }
      return [];
    },
    sortedMainCategories() {
      let newCategories = [];
      this.mainCategories.forEach((item) => {
        newCategories.push(item);
        if (item.child_categories && item.child_categories.length) {
          newCategories = newCategories.concat(
            item.child_categories.filter(
              (child) => child.status.code === this.$store.state.status.CODE_ACTIVE
            )
          );
        }
      });
      return newCategories;
    },
  },
  methods: {
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    filterContent(code) {
      if (this.form.code_gallery.value !== code) {
        this.form.code_gallery.value = code;
        this.submitFilterForm();
      }
    },
    /**
     * Фильтр новостей
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        const fd = new FormData();
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        Object.keys(this.form).forEach((key) => {
          if (key === "category_id") {
            if (this.form[key].value) fd.append(key, this.form[key].value.id);
          } else {
            if (this.form[key].value !== null) fd.append(key, this.form[key].value);
          }
        });

        this.$store.state.filterData = fd;
        await this.$store
          .dispatch("POST_MYCITY_MEDIA_FILTER", { data: fd })
          .then((response) => {
            this.loading = false;
            this.$store.state.filterResponse = response.data.galleries;
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'

.media-tabs {
  display flex
  flex-flow row wrap
  gap 16px
  justify-content flex-start
  width 100%
  margin-top: 24px;
}

.media-tab {
  display inline-flex

  &--faded {
    opacity 0.8
  }

  &__btn {
    background none
    border none
    cursor pointer
    padding 12px 16px
    display inline-flex
    align-items center
    transition .2s

    &--active
    &:hover {
      background: var(--color_gray_light);
      border-radius: var(--radius);
    }

    .icon {
      width 24px
      height 24px
      margin-right 8px

      svg {
        width 100%
        height 100%

        path {
          fill var(--color_dark)
        }
      }
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
  }
}
</style>
